<template>
  <div class="page-content col-lg-12 col-xl-11">
    <div class="register-content col-md-12">
      <form v-on:submit="checkForm" method="POST">
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <!-- panel-body begin -->
          <div class="panel-body">
            <div class="row form-group m-b-10">
              <h1
                class="page-header col-lg-2 col-md-3 col-form-label text-right"
              >
                政策新闻
              </h1>
            </div>
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >标题：</label
              >
              <div class="col-lg-10 col-md-9">
                <input
                  class="form-control"
                  data-parsley-range="[5,10]"
                  v-model="News.title"
                  required
                />
              </div>
            </div>
            <!-- <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >封面：</label
              >
              <div class="page-content col-lg-4 col-md-9">
                <div>
                  <upload
                    :maxSize="10"
                    @finishUpload="finish"
                    :value="News.cover"
                  >
                  </upload>
                </div>
              </div>
            </div> -->
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >作者：</label
              >
              <div class="col-lg-10 col-md-9">
                <input
                  class="form-control"
                  data-parsley-range="[5,10]"
                  v-model="News.author"
                  required
                />
              </div>
            </div>
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >排序：</label
              >
              <div class="col-lg-10 col-md-9">
                <input
                  class="form-control"
                  data-parsley-range="[5,10]"
                  v-model="News.orderNum"
                  required
                />
              </div>
            </div>
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >简介：</label
              >
              <div class="col-lg-10 col-md-9">
                <textarea
                  class="form-control col-lg-12 col-md-8"
                  rows="3"
                  data-parsley-range="[5,10]"
                  v-model="News.description"
                  required
                ></textarea>
              </div>
            </div>
            <div class="row form-group m-b-10">
              <label
                class="col-lg-2 col-md-3 col-form-label text-right"
              ></label>
              <div class="col-lg-10 col-md-9">
                <div id="toolbar-container"></div>
                <ckeditor
                  id="editor"
                  :editor="editor"
                  v-model="News.content"
                  @ready="onReady"
                  :config="editorConfig"
                  style="min-height: 300px;height:700px; "
                ></ckeditor>
              </div>
            </div>
            <p class="text-right p-t-10" style="margin: 0 0 0 0;">
              <button type="submit" class="btn btn-sm btn-success m-r-10">
                保存
              </button>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { newsApi } from "@/api/news/newsApi";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic"; //经典富文本 工具栏过于简单
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document"; //document 工具栏属性多
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn.js"; //语言汉字
// import "@ckeditor/ckeditor5-build-classic/build/translations/zh-cn.js"; //语言汉字

import { propertyService } from "@/api/utils/request";
class MyUploadAdapter {
  constructor(loader) {
    // Save Loader instance to update upload progress.
    this.loader = loader;
  }
  async upload() {
    var fileData = new FormData();
    fileData.append("fileContainerName", "default");
    fileData.append("file", await this.loader.file);
    fileData.append("fileType", 2);
    fileData.append("generateUniqueFileName", false);

    return new Promise((resolve, reject) => {
      return propertyService({
        url: "/api/file-management/add", //文件上传Api
        method: "post",
        data: fileData, // Api提交数据部分
        needToken: true,
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then((res) => {
          var resData = res.data.downloadInfo;
          resData.default = resData.downloadUrl; //赋值图片的url
          resolve(resData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
export default {
  props: {
    uploadUrl: {
      type: String,
      default: "/api/file-management/add",
    },
  },
  data() {
    return {
      loading: false,
      loader: [],
      News: {
        title: "",
        cover: "1",
        orderNum: "",
        description: "",
        author: "",
        content: "",
        id: "",
      },
      id: [],
      // editor: ClassicEditor,
      editor: DecoupledEditor,
      editorConfig: {
        language: "zh-cn",
      },
    };
  },
  mounted() {
    let id = this.$route.query.id;
    this.id = id;
    if (id) {
      this.GetNews();
    }
  },
  methods: {
    // 成功提醒
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "保存成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    // 错误提醒
    ToastWarning(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "False") {
        title = "保存出错";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "warning",
        title: title,
      });
    },
    GetNews() {
      let that = this;
      newsApi
        .GetNews({
          id: that.$route.query.id,
        })
        .then((res) => that.GetNewsSuccess(that, res))
        .finally(() => {});
    },

    checkForm: function(e) {
      e.preventDefault();
      const params = {
        Title: this.News.title,
        Cover: this.News.cover,
        OrderNum: this.News.orderNum,
        Description: this.News.description,
        Author: this.News.author,
        Content: this.News.content,
      };

      //e.preventDefault();
      let that = this;
      if (that.id) {
        newsApi
          .UpdateNews(that.id, params)
          .then((res) => that.UpdateNewsSuccess(that, res))
          .finally(() => {});
      } else {
        newsApi
          .CreateNews(params)
          .then((res) => that.CreateNewsSuccess(that, res))
          .finally(() => {});
      }
    },

    GetNewsSuccess(that, res) {
      if (res.success) {
        that.News = res.data;
        // that.d.file2.push(res.data.productionLicense);
      } else {
        console.log(res);
      }
    },
    CreateNewsSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        that.News = res.data;
      } else {
        that.ToastWarning(res);
        console.log(res);
      }
    },
    UpdateNewsSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        that.News = res.data;
      } else {
        that.ToastWarning(res);
      }
    },
    finish(e) {
      console.log(e);
      this.News.cover = e[0].res.data.fileInfo.id;
      console.log(e[0].res.data.fileInfo.id);
    },
    onReady(editor) {
      // 自定义上传图片插件
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
      // this.upload(this.loader);
      const toolbarContainer = document.querySelector("#toolbar-container");
      toolbarContainer.appendChild(editor.ui.view.toolbar.element);
      // editor.isReadOnly = true;
    },
  },
};
</script>
<style>
.ck.ck-editor__editable_inline {
  overflow: auto;
  padding: 2 var(--ck-spacing-standard);
  border: 1px solid #c3c3c3;
}
</style>
